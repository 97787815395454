import styled from 'styled-components'
import { Container } from 'bloomer';
import { Link as GatsbyLink } from "gatsby";
import {GlobalBackgroundColors} from "../../styles/GlobalStyles";

export const Header = styled.header`
  height: 100px;
  background-color: ${GlobalBackgroundColors.bgSecondary};
`

export const ContainerHeader = styled(Container)`
  position: relative;
  height: 100%;
  background-color: ${GlobalBackgroundColors.bgSecondary};
`

export const ContLogo = styled.div`
  position: relative;
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  padding-top: 5px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
`
export const LinkGatsby = styled(GatsbyLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;

  /* Typografhy */
  text-decoration: none;
  color: ${props => props.color};
  
  &:hover{
    color: ${props => props.color};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
`

export const ContButtonCotizacion = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
