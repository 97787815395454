import React from "react"
import {Columns, Column} from 'bloomer';
import {ContainerHeader, ContButtonCotizacion, ContLogo, Header, LinkGatsby} from './stylesHeaderPages';
import {LogoSecondary} from "../Logo/logoSecondary";
import ButtonGuzbarraf from "../UI/Button";
import {GlobalBackgroundColors} from "../../styles/GlobalStyles";

const handleCotizar = () => {
  var elmnt = document.getElementById("frmContact");
  elmnt.scrollIntoView({behavior: "smooth"});
  setTimeout(function(){
    document.getElementById('txtNombre').focus();
  }, 1000);
}

const langBtnTextoCotizar = {
  es: `Cotizar`,
  en: `Quote`
}

const HeaderPages = ( props ) => {
  console.log('HeaderPages -> props => ', props);
  console.log('langKey => ', props[0].props.lang);

  const lang = props[0].props.lang;

  return (
    <Header>
      <ContainerHeader>
        <Columns className="columns-responsive full-height">

          <Column
            className="full-height"
            isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6}}
          >
            <ContLogo>
              <LinkGatsby to={'/'} target="_self">
                <LogoSecondary/>
              </LinkGatsby>
            </ContLogo>

          </Column>

          <Column
            className="full-height no-display-mobile_"
            isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6}}
          >
            <ContButtonCotizacion>
              <ButtonGuzbarraf
                text={`${langBtnTextoCotizar[lang]}`}
                bgHover={GlobalBackgroundColors.bgPrimary}
                onClick={() => handleCotizar()}
              />
            </ContButtonCotizacion>
          </Column>

        </Columns>
      </ContainerHeader>
    </Header>
  )
}

export default HeaderPages
