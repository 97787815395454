import * as React from "react"
import LayoutPages from '../components/Layout/layoutPages'
import SEO from "../components/seo"

const LosAgavesPage = () => {
  return (
    <LayoutPages>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <div>LosAgavesPage</div>

    </LayoutPages>
  )
}

export default LosAgavesPage
